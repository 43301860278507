import React, { createContext, useContext, ReactNode } from 'react';
import axios from 'axios';

interface AuthContextProps {
  loginWithGoogle: () => void;
  loginWithFacebook: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  function login(provider: string) {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/${provider}`;
  }
  const loginWithGoogle = () => {
    login('google'); // auth.endchess.training/google
  };

  const loginWithFacebook = () => {
    login('facebook'); // auth.endchess.training/facebook
  };
  const logout = async () => {
    await axios.delete('/cookie');
    console.log('deleted cookie from the server');
  };

  return (
    <AuthContext.Provider
      value={{
        loginWithGoogle,
        loginWithFacebook,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
