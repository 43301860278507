import React from 'react';
import { PuzzlesComponent } from './PuzzlesComponent';
import usePuzzleData from './usePuzzleData';
import apiInstance from '../common/apiInstance';

const PuzzlesPage = ({ apiUrl: puzzlesUrl }: { apiUrl: string }) => {
  const { initialFen, moves, puzzleId, incrementPuzzleNum } =
    usePuzzleData(puzzlesUrl);

  const updatePlayers = async (completed: boolean) => {
    try {
      await apiInstance.put(`/players/completed`, { puzzleId });

      if (completed) {
        const playerResponse = await apiInstance.get(`/players`);
        const { elo: currentElo } = playerResponse.data;

        const newElo = currentElo + 10;
        await apiInstance.put(`/players/elo`, { elo: newElo });
      }
    } catch (error) {
      console.error('Error updating player:', error);
    }
  };

  if (!initialFen || moves.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <PuzzlesComponent
      initialFEN={initialFen}
      moves={moves}
      incPuzzleNum={incrementPuzzleNum}
      updatePlayers={updatePlayers}
    />
  );
};

export default PuzzlesPage;
