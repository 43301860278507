import React, { useState } from 'react';
import apiInstance from '../common/apiInstance';

import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';

const ImportGamesPage = () => {
  const [platform, setPlatform] = useState<'chesscom' | 'lichess'>('chesscom');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  type importResponse = {
    message: string;
    feedback: {
      inserts?: string[];
      duplicates?: string[];
    };
  };

  const handleImport = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const resposne = await apiInstance.post(`/import`, {
        otherPlatform: platform,
        otherUsername: username,
      });

      const importResponse = resposne.data as importResponse;
      const feedback = importResponse.feedback;

      if (feedback.inserts) {
        setSuccessMessage(
          `Successfully imported ${feedback.inserts.length} games for ${username} from ${platform}.`,
        );
      }
      if (feedback.duplicates) {
        setWarningMessage(
          `Warning: ${feedback.duplicates.length} games were duplicates.`,
        );
      }
    } catch (error) {
      console.error('Error importing games:', error);
      setErrorMessage(
        'Failed to import games. Please check the username and try again.', // todo setup with loading endpoint so that loaded games are still shown
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" className="title" m={5}>
        Import Chess Games
      </Typography>
      <Box maxWidth="sm">
        <form className="form" onSubmit={handleImport}>
          <FormControl fullWidth>
            <InputLabel id="platform-label">Select Platform:</InputLabel>
            <Select
              labelId="platform-label"
              id="platform"
              value={platform}
              onChange={(e) =>
                setPlatform(e.target.value as 'chesscom' | 'lichess')
              }
            >
              <MenuItem value="chesscom">Chess.com</MenuItem>
              <MenuItem value="lichess">Lichess</MenuItem>
            </Select>
          </FormControl>

          <TextField
            id="username"
            type="text"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
            required
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            fullWidth
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Importing...' : 'Import Games'}
          </Button>

          {successMessage && (
            <Alert
              onClose={() => setSuccessMessage('')}
              severity="success"
              variant="outlined"
            >
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              onClose={() => setErrorMessage('')}
              severity="error"
              variant="outlined"
            >
              {errorMessage}
            </Alert>
          )}
          {warningMessage && (
            <Alert
              onClose={() => setWarningMessage('')}
              severity="warning"
              variant="outlined"
            >
              {warningMessage}
            </Alert>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default ImportGamesPage;
