import React, { useEffect, useRef, useState } from 'react';
import { Chessboard } from 'react-chessboard';
import { PgnPosition as Position } from '../common/Position';
import './PlayerComponent.css';
import { Button, Container, Box } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';
type PlayerComponentProps = {
  PGN: string;
  incrementPositionNum: () => void;
  updatePlayers: () => void;
};

const PlayerComponent = ({
  PGN,
  incrementPositionNum,
  updatePlayers,
}: PlayerComponentProps) => {
  const positionRef = useRef<Position>(new Position(PGN));
  const [fen, setFen] = useState(positionRef.current.fen());
  const [status, setStatus] = useState<'play' | 'pause' | 'next' | 'end'>(
    'pause',
  );
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const [customSquareStyles, setCustomSquareStyles] = useState<
    Record<string, React.CSSProperties>
  >({});

  const clearIntervalIfExists = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const nextGame = () => {
    setCustomSquareStyles({});
    updatePlayers();
    incrementPositionNum();
    setStatus('next');
  };

  const setCheckSquare = (square: string) => {
    setCustomSquareStyles((prev) => ({
      ...prev,
      [square]: { backgroundColor: 'red' },
    }));
  };

  const next = () => {
    if (positionRef.current.next()) {
      setFen(positionRef.current.fen());
      setCustomSquareStyles({});
      setCheckSquare(positionRef.current.getCheckSquare());
    } else {
      setStatus('end');
      clearIntervalIfExists();
    }
  };

  const playGame = () => {
    setStatus('play');
    clearIntervalIfExists(); // Clear any existing interval before starting a new one
    intervalIdRef.current = setInterval(next, 1500);
  };

  const pauseGame = () => {
    setStatus('pause');
    clearIntervalIfExists();
  };

  useEffect(() => {
    positionRef.current = new Position(PGN);
    setFen(positionRef.current.fen());
    clearIntervalIfExists();
    playGame();
  }, [PGN]);

  const customDarkSquareStyle = { backgroundColor: '#838387' };
  const customLightSquareStyle = { backgroundColor: '#e1e1e3' };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" m={1} p={1}>
        <Chessboard
          position={fen}
          customSquareStyles={customSquareStyles}
          customDarkSquareStyle={customDarkSquareStyle}
          customLightSquareStyle={customLightSquareStyle}
        />
      </Box>
      <Box>
        {status === 'play' ? (
          <Button variant="contained" color="primary" onClick={pauseGame}>
            <Pause />
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={playGame}>
            <PlayArrow />
          </Button>
        )}
        {status === 'end' && (
          <Button variant="contained" disabled>
            End
          </Button>
        )}
        <Button variant="contained" onClick={nextGame}>
          Next Game
        </Button>
      </Box>
    </Container>
  );
};

export default PlayerComponent;
