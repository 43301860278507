import React, { useState, useEffect, useRef } from 'react';
import { FenPosition as Position } from '../common/Position';
import { Chessboard } from 'react-chessboard';
import { Container, Box, Button, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';

type PuzzlesComponentProps = {
  initialFEN: string;
  moves: string[];
  incPuzzleNum: () => void;
  updatePlayers: (completed: boolean) => void;
};

export const PuzzlesComponent = ({
  initialFEN,
  moves,
  incPuzzleNum,
  updatePlayers,
}: PuzzlesComponentProps) => {
  const positionRef = useRef<Position>(new Position(initialFEN, moves));
  const [status, setStatus] = useState<'active' | 'closed' | 'next'>('next');
  const [fen, setFen] = useState(initialFEN);
  const opponentColor: 'white' | 'black' =
    initialFEN.split(' ')[1] === 'b' ? 'black' : 'white';
  const [customSquareStyles, setCustomSquareStyles] = useState<
    Record<string, React.CSSProperties>
  >({});

  const setHintSquare = (square: string) => {
    setCustomSquareStyles((prev) => ({
      ...prev,
      [square]: { backgroundColor: '#77b1d4' },
    }));
  };

  const setCheckSquare = (square: string) => {
    setCustomSquareStyles((prev) => ({
      ...prev,
      [square]: { backgroundColor: 'red' },
    }));
  };

  const next = () => {
    if (positionRef.current.next()) {
      setCustomSquareStyles({});
      setFen(positionRef.current.fen());
      setCheckSquare(positionRef.current.getCheckSquare());
    } else {
      setStatus('closed');
      updatePlayers(true);
    }
  };

  useEffect(() => {
    positionRef.current = new Position(initialFEN, moves);
    setCustomSquareStyles({});
    setFen(initialFEN);
    setStatus('active');
    setTimeout(next, 1000);
  }, [initialFEN, moves]);

  const handlePieceDrop = (
    sourceSquare: string,
    targetSquare: string,
    piece: string,
  ) => {
    console.log('piece', piece);
    const move = `${sourceSquare}${targetSquare}`;
    const promotionPiece = piece[1].toLowerCase(); // 'wN' -> 'n'
    const isCorrect =
      positionRef.current.guess(move) ||
      positionRef.current.guess(`${move}${promotionPiece}`);
    if (isCorrect) {
      next();
      setTimeout(next, 500);
    } else {
      console.log('incorrect move');
      setCustomSquareStyles((prev) => ({
        ...prev,
        [targetSquare]: { backgroundColor: '#ff7f7f' },
      }));
      setTimeout(() => {
        setCustomSquareStyles({});
        setCheckSquare(positionRef.current.getCheckSquare());
      }, 500);
    }
    return isCorrect;
  };

  const handleHintClick = () => {
    const move = positionRef.current.hint();
    setHintSquare(move.slice(0, 2));
    setTimeout(() => {
      setCustomSquareStyles({});
      setCheckSquare(positionRef.current.getCheckSquare());
    }, 500);
  };

  const handleNextPuzzleClick = () => {
    setCustomSquareStyles({});
    incPuzzleNum();
    setStatus('next');
  };

  const blankFen = '8/8/8/8/8/8/8/8 w - - 0 1';

  const customDarkSquareStyle = { backgroundColor: '#838387' };
  const customLightSquareStyle = { backgroundColor: '#e1e1e3' };
  const playerColor = opponentColor === 'black' ? 'white' : 'black';
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" m={1} p={1}>
        <Chessboard
          position={status === 'next' ? blankFen : fen}
          onPieceDrop={status === 'active' ? handlePieceDrop : undefined}
          // onPromotionCheck={
          //   status === 'active' ? handlePromotionCheck : undefined
          // }
          boardOrientation={playerColor}
          arePiecesDraggable={status === 'active'}
          customSquareStyles={customSquareStyles}
          customDarkSquareStyle={customDarkSquareStyle}
          customLightSquareStyle={customLightSquareStyle}
        />
      </Box>
      <Box display="flex" justifyContent="left">
        <IconButton sx={{ backgroundColor: 'grey', marginRight: 4 }}>
          <PersonIcon sx={{ color: playerColor }} />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextPuzzleClick}
          style={{ marginRight: 10 }}
        >
          Next Puzzle
        </Button>
        {status === 'active' && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleHintClick}
            style={{ marginRight: 10 }}
          >
            Hint
          </Button>
        )}

        {status === 'closed' && (
          <Button
            variant="contained"
            color="success"
            style={{ marginRight: 10 }}
            startIcon={<CheckIcon />}
          ></Button>
        )}
      </Box>
    </Container>
  );
};
