import { useState, useEffect } from 'react';
import apiInstance from '../common/apiInstance';

const usePuzzleData = (puzzlesUrl: string) => {
  const [initialFen, setInitialFen] = useState('');
  const [moves, setMoves] = useState<string[]>([]);
  const [puzzleId, setPuzzleId] = useState(0);
  const [puzzleNum, setPuzzleNum] = useState(0);

  const fetchPosition = async () => {
    try {
      const response = await apiInstance.get(puzzlesUrl);
      const { FEN, Moves, _id } = response.data;
      setInitialFen(FEN);
      setMoves(Moves.split(' '));
      setPuzzleId(_id);
    } catch (error) {
      console.error('Error fetching position:', error);
    }
  };

  useEffect(() => {
    fetchPosition();
  }, [puzzleNum]);

  const incrementPuzzleNum = () => setPuzzleNum((prev) => prev + 1);

  return { initialFen, moves, puzzleId, puzzleNum, incrementPuzzleNum };
};

export default usePuzzleData;
