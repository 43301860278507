import { useState, useEffect } from 'react';
import apiInstance from '../common/apiInstance';

const useFetchPosition = (gamesUrl: string) => {
  const [pgn, setPgn] = useState('');
  const [gameId, setGameId] = useState('');
  const [gameNum, setGameNum] = useState(0);

  useEffect(() => {
    const fetchPosition = async () => {
      try {
        const response = await apiInstance.get(gamesUrl);
        const { pgn, _id } = response.data;
        console.log(pgn);
        setPgn(pgn);
        setGameId(_id);
      } catch (error) {
        throw new Error(`Error fetching position: ${error}`);
      }
    };

    fetchPosition();
  }, [gamesUrl, gameNum]);

  const incrementGameNum = () => {
    setGameNum((prevNum) => prevNum + 1);
  };

  return { pgn, gameId, incrementGameNum };
};

export default useFetchPosition;
