import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import TopMenu from './features/navigation/TopMenu';
import AppRoutes from './routes'; // Import the AppRoutes component
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <div>
          <TopMenu />
          <AppRoutes />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
