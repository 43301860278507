import React from 'react';
import PlayerComponent from './PlayerComponent';
import useFetchPosition from './useFetchPosition';
import apiInstance from '../common/apiInstance';

type PlayerPageProps = {
  apiUrl: string;
};

const GamesPage = ({ apiUrl: gamesUrl }: PlayerPageProps) => {
  const { pgn, gameId, incrementGameNum } = useFetchPosition(gamesUrl);
  const updatePlayers = async () => {
    try {
      await apiInstance.put(
        `/players/completed-game`, // todo this needs to become /${mongoose.object_id}
        { gameId },
      );
    } catch (error) {
      console.error('Error updating player:', error);
    }
  };

  return (
    <PlayerComponent
      PGN={pgn}
      incrementPositionNum={incrementGameNum}
      updatePlayers={updatePlayers}
    />
  );
};

export default GamesPage;
