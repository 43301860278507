// LoginFailed.tsx
import React from 'react';
// import './LoginFailed.css'; // Import the CSS file

const LoginFailed = () => {
  return (
    <div className="login-failed-container">
      <div className="login-failed-content">
        <h1>Login Failed</h1>
        <p>Sorry, your login failed. Please try again.</p>
      </div>
    </div>
  );
};

export default LoginFailed;
