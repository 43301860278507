// src/features/navigation/TopMenu.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Divider,
  SxProps,
} from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GridViewIcon from '@mui/icons-material/GridView';

export interface TopMenuProps {
  loginSx?: SxProps;
  puzzlesSx?: SxProps;
  hardPuzzlesSx?: SxProps;
  gamesSx?: SxProps;
  topGamesSx?: SxProps;
  importSx?: SxProps;
}

const TopMenu: React.FC<TopMenuProps> = ({
  loginSx,
  puzzlesSx,
  hardPuzzlesSx,
  gamesSx,
  topGamesSx,
  importSx,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Button
            color="inherit"
            component={Link}
            to="/login"
            sx={{ mr: 20, ...loginSx }}
          >
            <Typography>Login</Typography>
          </Button>
          <ExtensionIcon />
          <Button color="inherit" component={Link} to="/puzzles" sx={puzzlesSx}>
            <Typography>Puzzles</Typography>
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderRightWidth: 5 }}
          />
          <Button
            color="inherit"
            component={Link}
            to="/hard-puzzles"
            sx={{ mr: 10, ...hardPuzzlesSx }}
          >
            <Typography>Hard Puzzles</Typography>
          </Button>
          <GridViewIcon />
          <Button color="inherit" component={Link} to="/games" sx={gamesSx}>
            <Typography>Games</Typography>
          </Button>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderRightWidth: 5 }}
          />
          <Button
            color="inherit"
            component={Link}
            to="/top-games"
            sx={{ mr: 10, ...topGamesSx }}
          >
            <Typography>Top Games</Typography>
          </Button>
          <ImportExportIcon />
          <Button color="inherit" component={Link} to="/import" sx={importSx}>
            <Typography>Import Games</Typography>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopMenu;
