import React from 'react';
import { useRoutes } from 'react-router-dom';
import PuzzlesPage from './features/puzzles/PuzzlesPage'; // Import the new page
import GamesPage from './features/games/GamesPage';
import ImportGamesPage from './features/import/ImportGamesPage';
import AuthComponent from './features/auth/AuthComponent';
import LoginFailed from './features/auth/LoginFailed';

const AppRoutes = () => {
  const routes = [
    { path: '/puzzles', element: <PuzzlesPage apiUrl="/puzzles/random" /> },
    {
      path: '/hard-puzzles',
      element: <PuzzlesPage apiUrl="/puzzles/random-rated/2200" />,
    },
    { path: '/games', element: <GamesPage apiUrl="/games/random" /> },
    {
      path: '/top-games',
      element: <GamesPage apiUrl="/games/random-rated/2200" />,
    },
    {
      path: '/import',
      element: <ImportGamesPage />,
    },
    {
      path: '/login',
      element: <AuthComponent />,
    },
    {
      path: '/login-failed',
      element: <LoginFailed />,
    },
    {
      path: '/login-success',
      element: <h1>You are logged in!</h1>,
    },
  ];

  return useRoutes(routes);
};

export default AppRoutes;
