import React from 'react';
import { useAuth } from './AuthContext';
import { Button, Box, IconButton } from '@mui/material';
import { Google } from '@mui/icons-material';
const AuthComponent = () => {
  const { loginWithGoogle, logout } = useAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
      m={10}
    >
      <Box>
        <Button variant="contained" color="primary" onClick={loginWithGoogle}>
          Login with Google
        </Button>
        <IconButton onClick={loginWithGoogle}>
          <Google />
        </IconButton>
      </Box>
      {/* <Box className="facebook-login-button">
        <Button variant="contained" color="primary" onClick={loginWithFacebook}>
          Login with Facebook
        </Button>
      </Box> */}
      <Box sx={{ mt: 5 }}>
        <Button variant="contained" color="secondary" onClick={logout}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default AuthComponent;
